import * as qrcode from 'qrcode'
import { useEffect, useRef, useState } from 'react'
import { FaFilePdf, FaRegCommentAlt } from 'react-icons/fa'
import instructionFile from '../data/C19 Testing - Instructions for Healgen Rapid Antigen Test.pdf'

const isSSR = typeof window === 'undefined'

const isIOS = () => {
  const iosQuirkPresent = function () {
    const audio = new Audio()

    audio.volume = 0.5
    return audio.volume === 1 // volume cannot be changed from "1" on iOS 12 and below
  }

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  const isAppleDevice = navigator.userAgent.includes('Macintosh')
  const isTouchScreen = navigator.maxTouchPoints >= 1 // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()))
}

const RegistrationConfirmation = ({ location }) => {
  const [kitId, setKitId] = useState<string>()
  useEffect(() => {
    setKitId(new URLSearchParams(location.search).get('kitId'))
  }, [location.search])

  const isIos = isSSR ? false : isIOS()
  const isAndroid = isSSR ? false : navigator.userAgent.toLowerCase().includes('android')
  const isPhone = isIos || isAndroid

  const getSmsLink = (kitId: string, result: 'N' | 'P') => {
    if (isIos) return `sms:+447830320219&body=${kitId} ${result}`

    return `sms:+447830320219?body=${kitId} ${result}`
  }

  const posCanvas = useRef()
  const negCanvas = useRef()

  useEffect(() => {
    qrcode.toCanvas(negCanvas.current, `SMSTO:+447830320219:${kitId} N`, {
      margin: 0,
    })
    qrcode.toCanvas(posCanvas.current, `SMSTO:+447830320219:${kitId} P`, {
      margin: 0,
    })
  }, [kitId])

  return (
    <section className="section">
      <div className="container is-max-desktop">
        <h2 className="title has-text-midnightBlue is-spaced is-size-4-mobile">Thanks for registering! Next Steps:</h2>

        <h3 className="subtitle has-text-midnightBlue is-size-4 is-size-6-mobile">Take a Test</h3>

        <div className="content">
          <p>
            Follow the instructions found in your test kit. If the result is inconclusive, discard it and try again with
            a new test kit.
          </p>

          <p className="has-text-centered">
            <a
              className="button is-info is-outlined"
              href={instructionFile}
              download="C19 Testing - Instructions for Healgen Rapid Antigen Test.pdf"
            >
              <FaFilePdf className="mr-3" />
              Download Instructions
            </a>
          </p>

          <p>We've also made a short video to show you how to take your sample and perform a rapid antigen test:</p>

          <div className="video-container">
            <iframe
              title="Instruction video (Youtube)"
              className="horiz-center-block mt-6"
              src="https://www.youtube-nocookie.com/embed/xpipL2LJ9Yw"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>

        <h3 className="subtitle has-text-midnightBlue is-size-4 is-size-6-mobile mt-6">Text Us Your Result</h3>

        <div className="content">
          <div className="columns">
            <div className="column mr-6">
              <p>
                Once you have a positive or negative result, send a text message to <strong>07830 320219</strong>{' '}
                containing your ID (<strong>{kitId}</strong>) followed by either '<strong>N</strong>' for a negative
                result or '<strong>P</strong>' for a positive result.
              </p>
            </div>

            <div className="column">
              <div className="sms-convo mt-4">
                <div className="sms-title">+44 7830 320219</div>

                <p className="from-me">{kitId} N</p>
                <p className="from-them">Thanks, we've recorded your NEGATIVE rapid COVID test result.</p>
              </div>
              <p className="has-text-centered is-size-7">Example</p>
            </div>
          </div>

          <div className="is-flex is-flex-direction-row is-fullwidth is-align-items-center mt-5 is-hidden-mobile">
            <canvas ref={negCanvas} />

            <div className="ml-3">
              If your test is <strong>NEGATIVE</strong>
              <br />
              You can scan this QR code with your phone to send us your result
            </div>
            <div className="spacer" />
            <div className="has-text-right mr-3">
              If your test is <strong>POSITIVE</strong>
              <br />
              You can scan this QR code with your phone to send us your result
            </div>
            <canvas ref={posCanvas} />
          </div>
        </div>

        {isPhone && (
          <div className="is-hidden-tablet is-fullwidth">
            <p>Click one of these buttons to launch the messaging app on your phone and submit your result:</p>

            <div className="is-flex is-fullwidth is-justify-content-space-between mt-4">
              <a href={getSmsLink(kitId, 'N')} className="button is-success is-outlined is-flex-grow-1 mr-3">
                <FaRegCommentAlt className="mr-3" />
                Negative result
              </a>

              <a href={getSmsLink(kitId, 'P')} className="button is-danger is-outlined is-flex-grow-1">
                <FaRegCommentAlt className="mr-3" />
                Positive result
              </a>
            </div>
          </div>
        )}

        <h3 className="subtitle has-text-midnightBlue is-size-4 is-size-6-mobile mt-6">Repeat For Your Next Test</h3>

        <div className="content">
          <p>Next time you take a test, follow the above instructions to take the test and text us the result.</p>

          <p>
            You can bookmark this page to remember your ID, and you'll also receive all this information in an email.
          </p>
        </div>
      </div>
    </section>
  )
}

export default RegistrationConfirmation
